import React, { useRef } from 'react';

import Work from '../components/Work';
import Game from '../components/Game';
import About from '../components/About';
import ToolsCatalogue from '../components/ToolsCatalogue';
import Contact from '../components/Contact';
import Projects from '../components/Projects';
import Education from '../components/Education';

import Section from '../components/common/Section';
import CustomNavbar from '../components/common/CustomNavbar';

function Landing() {
  const aboutRef = useRef(null);
  const educRef = useRef(null);
  const workRef = useRef(null);
  const persRef = useRef(null);
  const contRef = useRef(null);

  const scrollToAbout = () => aboutRef.current.scrollIntoView();
  const scrollToEduc = () =>
    window.scrollTo({ top: educRef.current.offsetTop - 50, behavior: 'smooth' });
  const scrollToWork = () =>
    window.scrollTo({ top: workRef.current.offsetTop - 50, behavior: 'smooth' });
  const scrollToRef = () =>
    window.scrollTo({ top: persRef.current.offsetTop - 50, behavior: 'smooth' });
  const scrollToCont = () => contRef.current.scrollIntoView();

  return (
    <div className="App">
      {/* Custom Navbar */}
      <CustomNavbar
        scrollToAbout={scrollToAbout}
        scrollToEduc={scrollToEduc}
        scrollToWork={scrollToWork}
        scrollToRef={scrollToRef}
        scrollToCont={scrollToCont}
      />

      {/* Content */}
      <header className="App-header">
        <Section reference={aboutRef}>
          <About />
        </Section>

        <Game />

        <Section reference={educRef}>
          <Education />
        </Section>

        <Section reference={workRef}>
          <Work />
        </Section>

        <Section reference={persRef}>
          <Projects />
        </Section>
        
        <Section>
          <ToolsCatalogue />
        </Section>

        <Section reference={contRef}>
          <Contact />
        </Section>
      </header>
    </div>
  );
}

export default Landing;
