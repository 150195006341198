import React from 'react';

const Section = ({ children, reference }) => {
  return (
    <div style={{ width: '100%' }} ref={reference}>
      {children}
    </div>
  );
};

export default Section;
