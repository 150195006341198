import React from 'react';

const Seperator = ({ start, end, color, children }) => {
    return (
        <div style={{ paddingTop: start ? "0px" : "25px", paddingBottom: end ? "0px" : "25px", width: "100%", backgroundColor: color ? color : 'white' }}>
            {children}
        </div>
    )
}

export default Seperator