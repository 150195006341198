import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGamepad,
    faCube,
    faCode,
    faPalette,
    faPen,
    faVideo,
    faFileCode,
    faCodeBranch,
    faMobileAlt,
    faDatabase,
    faServer,
    faProjectDiagram,
    faCubesStacked
} from '@fortawesome/free-solid-svg-icons';

import Title from './common/Title';
import Seperator from './common/Seperator';

const ToolsCatalogue = () => {
    const tools = [
        { icon: faGamepad, name: 'Roblox Studio', backgroundColor: '#ffffff' },
        { icon: faCube, name: 'Unity', backgroundColor: '#ffffff' },
        { icon: faCode, name: 'Unreal', backgroundColor: '#ffffff' },
        { icon: faCube, name: 'Blender', backgroundColor: '#ffffff' },
        { icon: faPalette, name: 'Adobe XD', backgroundColor: '#ffffff' },
        { icon: faPen, name: 'Adobe Photoshop', backgroundColor: '#ffffff' },
        { icon: faVideo, name: 'Adobe Premiere', backgroundColor: '#ffffff' },
        { icon: faFileCode, name: 'Visual Studio', backgroundColor: '#ffffff' },
        { icon: faFileCode, name: 'Visual Studio Code', backgroundColor: '#ffffff' },
        { icon: faMobileAlt, name: 'Android Studio', backgroundColor: '#ffffff' },
        { icon: faDatabase, name: 'SSMS', backgroundColor: '#ffffff' },
        { icon: faCodeBranch, name: 'GIT', backgroundColor: '#ffffff' },
        { icon: faCubesStacked, name: 'DevOps', backgroundColor: '#ffffff' },
    ];

    const frameworks = [
        { icon: faCodeBranch, name: '.NET', backgroundColor: '#ffffff' },
        { icon: faServer, name: 'React', backgroundColor: '#ffffff' },
        { icon: faCubesStacked, name: 'MUI', backgroundColor: '#ffffff' },
        { icon: faProjectDiagram, name: 'VUE', backgroundColor: '#ffffff' },
        { icon: faCode, name: 'Bootstrap', backgroundColor: '#ffffff' },
    ]

    return (
        <Seperator color="#E6E6E6">
            <Container style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                {/* Title */}
                <Title text="Tool Experience" center />

                {/* Tool Cards */}
                <Row className="justify-content-center" style={{ marginTop: '50px' }}>
                    {tools.map((tool, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="2" className="mb-4">
                            <Card className="text-center h-100 card-content grow-on-hover" style={{ backgroundColor: tool.backgroundColor }}>
                                <Card.Body className="d-flex flex-column justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <FontAwesomeIcon icon={tool.icon} size="2x" className="mb-3" />
                                    <Card.Title>{tool.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Container style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                {/* Title */}
                <Title text="Framework Experience" center />

                {/* Tool Cards */}
                <Row className="justify-content-center" style={{ marginTop: '50px' }}>
                    {frameworks.map((tool, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="2" className="mb-4">
                            <Card className="text-center h-100 card-content grow-on-hover" style={{ backgroundColor: tool.backgroundColor }}>
                                <Card.Body className="d-flex flex-column justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <FontAwesomeIcon icon={tool.icon} size="2x" className="mb-3" />
                                    <Card.Title>{tool.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Seperator>
    );
};

export default ToolsCatalogue;
