import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Title from './common/Title';
import Seperator from './common/Seperator';
import Note from './common/Note';

const Work = () => {
    return (
        <Seperator color="#E6E6E6">
            <Container style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                {/* Title */}
                <Title text="Work Experience" center />

                {/* Work Experience */}
                <Container style={{ marginTop: '25px' }}>
                    <Row className="justify-content-md-center">
                        {/* Associate Developer */}
                        <Col md="auto">
                            <Container>
                                <Note
                                    title={`Associate Developer\n(Internship)`}
                                    company="Bluefort Ltd."
                                    date="2022-2023"
                                />
                            </Container>
                        </Col>
                        {/* Software Developer */}
                        <Col md="auto">
                            <Container>
                                <Note
                                    title="Software Developer"
                                    company="Bank of Valletta p.l.c."
                                    date="2024-Present"
                                />
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Seperator>
    );
};

export default Work;
