import React, { useState, useEffect } from 'react';
import { Container, Stack } from "react-bootstrap";
import Title from './common/Title';
import Seperator from "./common/Seperator";
import ScrollCharacter from './common/ScrollCharacter';

const Contact = () => {
    const emailAddress = 'admin@lukezammit.net';
    const [showCharacter, setShowCharacter] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.innerHeight + window.scrollY;
        const documentHeight = document.body.offsetHeight;

        if (scrollPosition >= documentHeight - 50) {
            setShowCharacter(true);
        } else {
            setShowCharacter(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Seperator color='#202020' end>
            <Container style={{ paddingBottom: '25px', textAlign: 'center', color: 'white' }}>
                <Title text="Contact Me" center />
                <h6 style={{ color: 'white', marginTop: '25px', marginBottom: '25px' }}>
                    Feel free to reach out to me via email at:{'\n'}
                    <a href={`mailto:${emailAddress}`} style={{ color: 'white' }}>
                        {emailAddress}
                    </a>
                    <br />
                    I'll do my best to respond promptly! Additionally, you can find me on various social media platforms if you prefer.
                </h6>

                <Stack direction="horizontal" gap={3} className="mx-auto justify-content-center" styles={{ display: 'inline', marginLeft: 'auto', marginRight: 'auto' }}>
                    <a href='https://www.linkedin.com/in/luke-zammit-80144a175/' style={{ color: 'white' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                        </svg>
                    </a>
                    <a href='https://twitter.com/l_kezammit' style={{ color: 'white' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                    </a>
                </Stack>
                <ScrollCharacter show={showCharacter} />
            </Container>
        </Seperator>
    );
}

export default Contact;
