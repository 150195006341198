import React from 'react';
import { Badge, Card, CardBody, CardImg, CardTitle, Button } from 'react-bootstrap';

const Project = ({ data }) => {
    return (
        <Card className="grid-item card card-content grow-on-hover" style={{ height: '100%', backgroundColor: '#202020', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
            <CardImg src={require('../../assets/' + data.image)} variant="top" alt=""></CardImg>

            <CardBody className="d-flex flex-column">
                <CardTitle style={{ fontWeight: 'bold', color: 'white' }}>
                    {data.name} {data.unreleased && <Badge bg="danger">Unreleased</Badge>}
                </CardTitle>
                {data.desc &&
                    <div style={{ marginBottom: '1rem' }}>
                        {data.desc.map((desc, i) => (
                            <p key={data.name + desc} style={{ fontWeight: 'normal', color: 'white', fontSize: '1rem', marginBottom: '0.05rem' }}>⚪ {desc}</p>
                        ))}
                    </div>
                }
                <Button
                    className="mt-auto"
                    style={{ fontWeight: 'bold', color: 'black', backgroundColor: 'white', border: 'black' }}
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Redirect to Project
                </Button>
            </CardBody>
        </Card>
    );
};

export default Project;
