import React, { useState, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Cookies from 'js-cookie';
import Section from '../common/Section';
import Seperator from '../common/Seperator';
import Title from '../common/Title';

const PagaLeave = () => {
    const [totalLeave, setTotalLeave] = useState('');
    const [leaveUnit, setLeaveUnit] = useState('days');
    const [leaveType, setLeaveType] = useState('');
    const [leaveDuration, setLeaveDuration] = useState('');
    const [leaveDurationUnit, setLeaveDurationUnit] = useState('days');
    const [leaves, setLeaves] = useState([]);
    const [remainingLeave, setRemainingLeave] = useState(0);
    const [displayUnit, setDisplayUnit] = useState('days');

    const HOURS_IN_WORKDAY = 8;

    useEffect(() => {
        const savedTotalLeave = Cookies.get('totalLeave');
        if (savedTotalLeave) {
            setTotalLeave(savedTotalLeave);
        }

        const savedLeaveUnit = Cookies.get('leaveUnit');
        if (savedLeaveUnit) {
            setLeaveUnit(savedLeaveUnit);
        }
    }, []);

    const calculateRemainingLeave = useCallback(() => {
        // Convert total leave to hours
        let totalLeaveInHours = leaveUnit === 'days' ? parseFloat(totalLeave) * HOURS_IN_WORKDAY : parseFloat(totalLeave);

        // Subtract each leave duration in hours
        leaves.forEach(leave => {
            const leaveInHours = leave.unit === 'days' ? leave.duration * HOURS_IN_WORKDAY : leave.duration;
            totalLeaveInHours -= leaveInHours;
        });

        // Convert remaining leave to the display unit
        const remainingLeaveInDisplayUnit = displayUnit === 'days' ? (totalLeaveInHours / HOURS_IN_WORKDAY).toFixed(2) : totalLeaveInHours.toFixed(2);
        setRemainingLeave(remainingLeaveInDisplayUnit);
    }, [totalLeave, leaveUnit, leaves, displayUnit]);

    useEffect(() => {
        calculateRemainingLeave();
    }, [totalLeave, leaveUnit, leaves, displayUnit, calculateRemainingLeave]);

    const handleAddLeave = () => {
        if (leaveType && leaveDuration) {
            const newLeave = {
                type: leaveType,
                duration: parseFloat(leaveDuration),
                unit: leaveDurationUnit
            };
            setLeaves([...leaves, newLeave]);
            setLeaveType('');
            setLeaveDuration('');
        }
    };

    const handleEditLeave = (index) => {
        const leave = leaves[index];
        setLeaveType(leave.type);
        setLeaveDuration(leave.duration);
        setLeaveDurationUnit(leave.unit);
        handleRemoveLeave(index);
    };

    const handleRemoveLeave = (index) => {
        const newLeaves = [...leaves];
        newLeaves.splice(index, 1);
        setLeaves(newLeaves);
    };

    const handleTotalLeaveChange = (event) => {
        const value = event.target.value;
        setTotalLeave(value);
        Cookies.set('totalLeave', value, { expires: 7 });
    };

    const handleLeaveUnitChange = (event) => {
        const value = event.target.value;
        setLeaveUnit(value);
        Cookies.set('leaveUnit', value, { expires: 7 });
        calculateRemainingLeave();
    };

    const handleDisplayUnitChange = (event) => {
        setDisplayUnit(event.target.value);
    };


    return (
        <Section>
            <Seperator>
                <div className={`alert alert-warning`} role="alert" style={{ maxWidth: '50rem', margin: 'auto', padding: '50px' }}>
                    <Title text="Remaining Paga Leave!?" center />
                    <div className="form-group" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        <label>Total Leave: </label>
                        <input
                            type="number"
                            className="form-control"
                            value={totalLeave}
                            onChange={handleTotalLeaveChange}
                            required
                            style={{ maxWidth: '10rem', display: 'inline-block', marginRight: '10px' }}
                        />
                        <select className="form-control" value={leaveUnit} onChange={handleLeaveUnitChange} style={{ maxWidth: '10rem', display: 'inline-block' }}>
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                        </select>
                    </div>

                    <br />

                    <div className="form-group" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        <label>Leave Type: </label>
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            value={leaveType}
                            onChange={(e) => setLeaveType(e.target.value)}
                            style={{ maxWidth: '20rem', display: 'inline-block', marginRight: '10px' }}
                        />
                        <input
                            type="number"
                            className="form-control"
                            value={leaveDuration}
                            onChange={(e) => setLeaveDuration(e.target.value)}
                            required
                            style={{ maxWidth: '10rem', display: 'inline-block', marginRight: '10px' }}
                        />
                        <select className="form-control" value={leaveDurationUnit} onChange={(e) => setLeaveDurationUnit(e.target.value)} style={{ maxWidth: '10rem', display: 'inline-block' }}>
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                        </select>
                        <button className="btn btn-primary" onClick={handleAddLeave} style={{ margin: 'auto' }}>Add Leave</button>
                    </div>

                    <br />

                    {(leaves != null && leaves.length > 0) &&
                        <>
                            <div>
                                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', margin: 'auto' }}>Leaves</h3>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Leave Description</th>
                                            <th>Duration</th>
                                            <th>Unit</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaves.map((leave, index) => (
                                            <tr key={index}>
                                                <td>{index}</td>
                                                <td>{leave.type}</td>
                                                <td>{leave.duration}</td>
                                                <td>{leave.unit}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-secondary ml-2" onClick={() => handleEditLeave(index)}>Edit</button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-sm btn-danger ml-2" onClick={() => handleRemoveLeave(index)}>Remove</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <br />
                        </>
                    }

                    <div className="form-group" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        <label>Display Remaining Leave In: </label>
                        <select className="form-control" value={displayUnit} onChange={handleDisplayUnitChange} style={{ maxWidth: '10rem', display: 'inline-block' }}>
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                        </select>
                    </div>

                    <div>
                        <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Remaining Leave: {remainingLeave} {displayUnit}</h3>
                    </div>
                </div>
            </Seperator>
        </Section >
    );
};

export default PagaLeave;
