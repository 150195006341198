import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { Player, ControlBar } from 'video-react';

import Seperator from './common/Seperator';

import Portrait from '../assets/pfp.jpg';
const Video = require('../assets/demo.webm');

const About = () => {
    return (
        <Seperator start={true} >
            <div
                style={{
                    top: '0',
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                    maxHeight: '600px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
                }}
            >
                <Player
                    playsInline
                    autoPlay
                    muted
                    loop
                    src={Video}
                >
                    <ControlBar disableCompletely={true} disableDefaultControls={true} />
                </Player>

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        // backdropFilter: 'blur(2px)',
                    }}
                />
            </div>
            <Seperator>
                <Container style={{ paddingTop: '50px' }}>
                    <Row className="justify-content-md-center">
                        <Col md={4} className="d-flex justify-content-center">
                            <div style={{ textAlign: 'justify', textJustify: 'inner-word', width: '200px', height: '200px', overflow: 'hidden', borderRadius: '50%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
                                <img src={Portrait} alt="Profile" style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </Col>
                        <Col md={8} className="d-flex align-items-center">
                            <div>
                                <h2 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    About Me
                                </h2>
                                <p style={{ fontSize: "18px" }}>Hi there! I'm Luke Zammit, an experienced developer who loves creating engaging games and websites. I've been working on personal projects in the gaming world for over six years, and I'm always eager to come up with new ideas. While I'm no expert, I'm comfortable working with languages like C#, C++, Lua, Python, and JavaScript. Turning ideas into reality through coding is what keeps me going!</p>
                                <p style={{ fontSize: "18px" }}>In my journey through game development, I've become skilled with tools like Blender, Roblox Studio, Unreal, and Unity. I'm inspired by the potential of digital storytelling and user interaction. I'm dedicated to pushing boundaries and working with others who share my passion to create captivating experiences. With a focus on excellence and a collaborative mindset, I'm excited to keep shaping the future of gaming and web development.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Seperator>
        </Seperator>
    )
}

export default About