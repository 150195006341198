import React, { useRef } from 'react';

import { Container, Row, Col, Stack } from 'react-bootstrap';

import RobloxProjects from '../misc/RobloxProjects.json';
import UnityProjects from '../misc/UnityProjects.json';

import Title from './common/Title';
import Engine from './common/Engine';
import Project from './common/Project';
import Seperator from './common/Seperator';

import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Projects = () => {
    const robloxProjectRefs = useRef([]);
    const unityProjectRefs = useRef([]);

    const isRobloxProjectVisible = useIntersectionObserver(robloxProjectRefs);
    const isUnityProjectVisible = useIntersectionObserver(unityProjectRefs);

    const renderDelay = 200;

    return (
        <Seperator color="white">
            <Container>
                <Title text="Personal Projects" center />
                <Stack gap={3}>
                    {/* Roblox Projects */}
                    <Engine text="Roblox" badge="Lua" badgeColor={'#24b4d8'}>
                        <Container ref={robloxProjectRefs}>
                            <Row>
                                {RobloxProjects.map((project, index) => (
                                    <Col
                                        key={project.name}
                                        className={`p-2 project-container ${isRobloxProjectVisible ? 'loaded' : ''}`}
                                        sm
                                        style={{ transitionDelay: `${index * renderDelay}ms` }}
                                    >
                                        <Project data={project} />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Engine>
                    {/* Unity Projects */}
                    <Engine text="Unity" badge="C#" badgeColor={'#8626e0'}>
                        <Container ref={unityProjectRefs}>
                            <Row>
                                {UnityProjects.map((project, index) => (
                                    <Col
                                        key={project.name}
                                        className={`p-2 project-container ${isUnityProjectVisible ? 'loaded' : ''}`}
                                        sm
                                        style={{ transitionDelay: `${index * renderDelay}ms` }}
                                    >
                                        <Project data={project} />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Engine>
                    {/* Unreal Projects */}
                    <Engine text="Unreal" badge="C++" badgeColor={'#4526e0'}>
                        <div style={{ paddingTop: '150px', paddingBottom: '150px' }}>
                            <p className="mb-3 jumping-text" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                ⚒️ Work in Progress ⚒️
                            </p>
                        </div>
                    </Engine>
                </Stack>
            </Container>
        </Seperator>
    );
};

export default Projects;
