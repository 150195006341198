import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import PagaWeek from './pages/PagaWeek';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/PagaWeek" element={<PagaWeek />} />
            </Routes>
        </Router>
    );
}

export default App;
