// src/components/PagaTime.js
import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import Cookies from 'js-cookie';
import Seperator from '../common/Seperator';
import Section from '../common/Section';
import Title from '../common/Title';

const PagaTime = () => {
  const [currentDay, setCurrentDay] = useState('');
  const [payDate, setPayDate] = useState('');
  const [isPagaWeek, setIsPagaWeek] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setCurrentDay(today);

    const savedPayDate = Cookies.get('payDate');
    if (savedPayDate) {
      setPayDate(savedPayDate);
    }
  }, []);

  useEffect(() => {
    if (currentDay && payDate) {
      const currentDate = new Date(currentDay);
      const payDateObj = new Date(payDate);
      payDateObj.setFullYear(currentDate.getFullYear());

      const startWeek = new Date(payDateObj);
      const endWeek = new Date(payDateObj);
      endWeek.setDate(endWeek.getDate() + 7);

      const isPagaWeek = currentDate >= startWeek && currentDate <= endWeek;
      setIsPagaWeek(isPagaWeek);

      if (isPagaWeek) {
        confetti();
      }
    }
  }, [currentDay, payDate]);

  const handlePayDateChange = (event) => {
    const selectedDate = event.target.value;
    setPayDate(selectedDate);
    Cookies.set('payDate', selectedDate, { expires: 7 });
  };

  return (
    <Section>
      <Seperator >
        <div className={`alert ${isPagaWeek ? 'alert-success' : 'alert-danger'}`} role="alert" style={{ maxWidth: '50rem', margin: 'auto', padding: '50px' }}>
          <Title text="Is it paga week!?" center />

          <div className="form-group d-flex justify-content-center align-items-center" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
            <label htmlFor="payDate" className="col-form-label" style={{ minWidth: '150px', marginRight: '10px' }}>Estimated Pay Date:</label>
            <input
              type="date"
              className="form-control"
              id="payDate"
              name="payDate"
              value={payDate}
              onChange={handlePayDateChange}
              required
              style={{ maxWidth: '15rem' }}
            />
          </div>

          <div id="results" className="result text-center" style={{ marginTop: "1rem" }}>
            <span className="emoji" style={{ fontSize: '5rem' }}>{isPagaWeek ? '😊 Yes!' : '😭 No!'}</span>
          </div>
        </div>
      </Seperator>
    </Section>
  );
};

export default PagaTime;
