import React, { useRef } from 'react';
import Contact from '../components/Contact';

import PagaTime from '../components/paga/PagaTime';
import PagaLeave from '../components/paga/PagaLeave';

import Section from '../components/common/Section';
import CustomNavbar from '../components/common/CustomNavbar';

function PagaWeek() {
  const contRef = useRef(null);
  const scrollToCont = () => contRef.current.scrollIntoView();

  return (
    <div className="App">
      <CustomNavbar scrollToCont={scrollToCont} />
      {/* Content */}
      <header className="App-header">
        <Section>
          <div className='rainbow'
            style={{
              margin: 'auto',
              paddingTop: '25px',
              paddingBottom: '25px',
              width: "100%",
              backgroundColor: '#E6E6E6',
              WebkitBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.4)',
              boxShadow: '0 4px 4px rgba(0, 0, 0, 0.4)'
            }}>
            <h1
              className="jumping-text"
              style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', marginTop: '7rem', marginBottom: '7rem' }}
            >Welcome to the Secret Zone! 😎</h1>
          </div>
        </Section>

        <PagaTime />
        <PagaLeave />

        <Section reference={contRef}>
          <Contact />
        </Section>
      </header>
    </div>
  );
}

export default PagaWeek;