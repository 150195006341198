import React, { useState, useEffect, useRef } from 'react';

import CharlieIdle from '../assets/sprites/Charlie_idle.gif';
import CharlieRun from '../assets/sprites/Charlie_run.gif';

const Game = () => {
    const [humanoid, setHumanoid] = useState({
        isWalking: false,
        isLeft: false,
        pos: 50,
        dur: 0,
    });

    const [isMoving, setMove] = useState(false);

    const randomPosition = () => {
        const newPosition = Math.random() * 80 + 10;
        const distance = Math.abs(newPosition - humanoid.pos) / 100;
        const duration = (distance * 7.5) * 1000;

        return [newPosition, duration, humanoid.pos < newPosition];
    };

    const moveHumanoid = useRef(() => { });

    moveHumanoid.current = () => {
        if (isMoving) {
            const [position, dur, dir] = randomPosition();

            setHumanoid({
                isWalking: true,
                isLeft: dir,
                pos: position,
                dur: dur
            });

            setTimeout(() => {
                setHumanoid({
                    isWalking: false,
                    isLeft: dir,
                    pos: position,
                    dur: dur
                });

                setTimeout(() => {
                    setMove(false);
                }, Math.random() * 8000 + 8000);

            }, dur);
        } else {
            setMove(true);
        }
    };

    useEffect(() => {
        moveHumanoid.current();
    }, [isMoving]);

    return (
        <div className="game" style={{ backgroundColor: 'white', pointerEvents: 'none', bottom: "0", width: '100%', height: 'auto' }}>
            <img
                style={{
                    width: '5%',
                    height: 'auto',
                    minHeight: '64px',
                    minWidth: '64px',
                    transform: humanoid.isLeft ? 'scaleX(-1)' : 'scaleX(1)',
                    transition: `margin-left ${humanoid.dur / 1000}s ease-in`,
                    marginLeft: `${humanoid.pos}%`,
                    marginBottom: '-7px',
                    imageRendering: 'pixelated'
                }}
                className="character"
                src={humanoid.isWalking ? CharlieRun : CharlieIdle}
                alt="avatar"
            />
        </div>
    );
};

export default Game;
