import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

const CustomNavbar = ({ scrollToAbout, scrollToEduc, scrollToWork, scrollToRef, scrollToCont }) => {
  const location = useLocation();
  const isPagaWeek = location.pathname === '/PagaWeek';

  return (
    <Navbar bg={"dark"} expand="lg" variant={"dark"} style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
      <Container>
        {/* Brand */}
        <Navbar.Brand style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={scrollToAbout}>Luke Zammit</Navbar.Brand>

        {/* Navbar Toggle Button */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        {/* Navbar Content */}
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          {isPagaWeek ?
            <Nav>
              <Nav.Link className="rainbow rainbow_text_animated" to="/PagaWeek" onClick={scrollToAbout} style={{ textDecoration: 'none' }}>🥳 Paga Week 💵</Nav.Link>
              <CustomNavLink to="/" onClick={scrollToAbout}>Portfolio</CustomNavLink>
              <CustomNavLink to="/" onClick={scrollToCont}>Contact</CustomNavLink>
            </Nav>
            :
            <Nav>
              {/* Navbar Links */}
              <CustomNavLink to="/" onClick={scrollToAbout}>About</CustomNavLink>
              <CustomNavLink to="/" onClick={scrollToEduc}>Education</CustomNavLink>
              <CustomNavLink to="/" onClick={scrollToWork}>Work</CustomNavLink>
              <CustomNavLink to="/" onClick={scrollToRef}>Personal Projects</CustomNavLink>
              <CustomNavLink to="/" onClick={scrollToCont}>Contact</CustomNavLink>
            </Nav>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// Custom NavLink Component
const CustomNavLink = ({ to, children, onClick }) => {
  return (
    <Nav.Link
      as={NavLink}
      to={to}
      style={({ isActive }) => ({
        textDecoration: 'none',
        color: isActive ? 'white' : 'grey', // Set color to white if active, otherwise grey
        transition: 'color 0.3s ease', // Add transition for smooth hover effect
      })}
      className={({ isActive }) => isActive ? 'active' : ''}
      onClick={onClick}
    >
      {children}
    </Nav.Link>
  );
};

export default CustomNavbar;
