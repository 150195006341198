import React from 'react';

import { Container, Row, Col, Stack } from 'react-bootstrap';

import Title from './common/Title';
import Seperator from './common/Seperator';
import Note from './common/Note';

const Education = () => {
    return (
        <Seperator color="#202020">
            <Container style={{ paddingTop: '25px', paddingBottom: '25px', color: 'white' }}>
                <Row className="justify-content-md-center">
                    <Col md={6} className='education-container'>
                        <Title text="Education" />
                        <Stack style={{ marginTop: '25px' }}>
                            <Note
                                className="hstack"

                                title="BSc. (Hons.) in Multimedia Software Development"
                                company="MCAST"
                                date="2020-2023"
                            />

                            <div style={{ height: '10px' }} />

                            <Note
                                className="hstack"

                                title="Advanced Diploma in Multimedia Software Development"
                                company="MCAST"
                                date="2018-2020"
                            />
                        </Stack>
                    </Col>
                    <Col md={6}>
                        <Title text="Certificates" />
                        <Stack style={{ marginTop: '25px' }}>
                            <Note
                                className="hstack"

                                title="Remote Sensing and Earth Observation using Satellites (REMSEN)"
                                company="MCAST"
                                date="2021"
                            />

                            <div style={{ height: '10px' }} />

                            <Note
                                className="hstack"

                                title="AI hackathon (Erasmus+)"
                                company="MCAST"
                                date="2022"
                            />
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </Seperator>
    )
}

export default Education
