import React from 'react';

const Engine = ({ text, badge, badgeColor, children }) => {
    return (
        <div className="p-2">
            <h4 style={{ fontWeight: "bold" }}>
                {text} <span className='badge' style={{ backgroundColor: badgeColor }}>{badge}</span>
            </h4>

            {children}
        </div>
    )
}

export default Engine