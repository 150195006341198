import SubTitle from "./SubTitle"

const Note = ({ title, company, date }) => {
    return (
        <div style={{ width: 'auto', minWidth: '200hv' }}>
            <SubTitle text={title} />
            <div style={{ float: "left", marginLeft: "10px" }}>
                <span style={{ display: 'block', fontSize: 20 }}>{company}</span>
                <span style={{ display: 'block', fontSize: 15, color: "grey" }}>{date}</span>
            </div>
        </div>
    )
}

export default Note