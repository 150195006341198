import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ScrollCharacter.css';

const ScrollCharacter = ({ show }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/PagaWeek');
  };

  return (
    <div className={`scroll-character ${show ? 'show' : ''}`} onClick={handleClick}>
      <img src={require("../../assets/voh.png")} alt="Character" style={{ height: 'auto', maxWidth: '70px' }} />
    </div>
  );
};

export default ScrollCharacter;
